import * as React from "react";
import { useEffect, useState, useCallback } from "react";
import getInkeepSettings from "./getInkeepSettings";
import type { InkeepCustomTriggerProps } from "@inkeep/uikit";

const InkeepCustom = ({ isInkeepOpen, setIsInkeepOpen }) => {
  const [CustomTrigger, setCustomTrigger] =
    useState<(e: InkeepCustomTriggerProps) => JSX.Element>();

  const { baseSettings, aiChatSettings, searchSettings, modalSettings } = getInkeepSettings();

  // this is the inkeep recomended way to load with Gatsby, we can't just import the package in React
  useEffect(() => {
    const loadCustomTrigger = async () => {
      try {
        const { InkeepCustomTrigger } = await import("@inkeep/uikit");
        setCustomTrigger(() => InkeepCustomTrigger);
      } catch (error) {
        console.error("Failed to load SearchBar:", error);
      }
    };

    loadCustomTrigger();
  }, []);

  const handleClose = useCallback(() => {
    setIsInkeepOpen(false);
  }, []);

  const inkeepCustomTriggerProps: InkeepCustomTriggerProps = {
    isOpen: isInkeepOpen,
    onClose: handleClose,
    baseSettings,
    aiChatSettings,
    searchSettings,
    modalSettings,
  };

  return CustomTrigger && <CustomTrigger {...inkeepCustomTriggerProps} />;
};

export default InkeepCustom;
