import { HashContext } from "components/docs/shared-components/HashContext";
import React, { useCallback, useContext, useEffect, useState } from "react";
import styled, { css } from "styled-components";
import { RouterLocation } from "../../../types/types";
import Footer from "../layout/Footer";
import LeftNavigationBar from "./LeftNavigationBar";
import MobileNavigationBar from "./MobileNavigationBar";
import NavHelmet from "./NavHelmet";
import TopNavigationBar, { TOP_NAV_HEIGHT } from "./TopNavigationBar";
import MendableModal from "components/generic/MendableModal";
import InkeepModal from "components/generic/InkeepModal";
interface Props {
  children: JSX.Element;
  location: RouterLocation;
}

const TopNavBarFlexContainer = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
`;

/**
 * Can't have overflow hidden or children can't be sticky. The 1px
 * difference on the width corrects for the sidebar size I think.
 */
const VerticalStretchContainer = styled.div`
  display: flex;
  align-items: stretch;
  width: calc(100% - 1px);
  padding: 0;
  margin: 0;
  flex-grow: 1;
`;

// Can't have overflow hidden or children can't be sticky
const HorizontalStretchContainer = styled.div`
  position: relative;
  height: 100%;
  padding: 0;
  margin: 0;
  flex-grow: 1;
  min-width: 0;
`;

/**
 * Creates a wrapper element whose content stretches to fill
 * the entire screen height if neccessary, ensuring the footer
 * is just offscreen. Certain pages like 404 may opt to make
 * this not fullscreen.
 */
const Content = styled.div<{ $isFullscreen: boolean }>`
  ${({ $isFullscreen }) =>
    $isFullscreen &&
    css`
      min-height: calc(100vh - ${TOP_NAV_HEIGHT}px);
    `};
  margin: 0;
  padding: 0;
  width: 100%;
`;

/**
 * Responsible for keeping the HashContext's `hash` value up to date when the user clicks
 * different links that may include different hash values, in addition to rendering the
 * navigation components around the main content on mobile and desktop.
 */
const Navigation = ({ children, location }: Props) => {
  const { hash, lastChangeTimestamp, setHash } = useContext(HashContext);
  const [isMendableVisible, setIsMendableVisible] = useState(false);
  const [isInkeepVisible, setIsInkeepVisible] = useState(false);

  // Attempts to parse the location's key into a timestamp and returns it if valid to do so
  const keyAsTimestamp = useCallback(() => {
    if (!location.key || location.key === "initial") {
      return null;
    }
    try {
      const value = parseInt(location.key, 10);
      return value > 0 ? value : null;
    } catch {
      return null;
    }
  }, [location.key]);

  // If the location was a timestamp and it's newer than our last change, that means we clicked a link and the hash changed. We should update the hash from the location.
  useEffect(() => {
    const locationHash = location.hash.replace("#", "");
    const timestamp = keyAsTimestamp();
    if (locationHash !== hash && timestamp && lastChangeTimestamp < timestamp) {
      setHash(locationHash, timestamp);
    }
  }, [hash, setHash, location, lastChangeTimestamp, keyAsTimestamp]);

  return (
    <>
      <NavHelmet />
      <MobileNavigationBar
        location={location}
        setIsMendableVisible={setIsMendableVisible}
        setIsInkeepVisible={setIsInkeepVisible}
      />
      <TopNavBarFlexContainer>
        <TopNavigationBar
          location={location}
          setIsMendableVisible={setIsMendableVisible}
          setIsInkeepVisible={setIsInkeepVisible}
        />
        <VerticalStretchContainer>
          <LeftNavigationBar location={location} />
          <HorizontalStretchContainer>
            <Content $isFullscreen={location.key !== "404"}>{children}</Content>
            <Footer />
          </HorizontalStretchContainer>
        </VerticalStretchContainer>
      </TopNavBarFlexContainer>
      <MendableModal
        isMendableModalOpen={isMendableVisible}
        setIsMendableOpen={setIsMendableVisible}
      />
      <InkeepModal isInkeepModalOpen={isInkeepVisible} setIsInkeepModalOpen={setIsInkeepVisible} />
    </>
  );
};

export default Navigation;
