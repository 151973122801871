import React, { Dispatch, SetStateAction, useRef } from "react";
import styled from "styled-components";
import Modal, { ModalRef } from "./Modal";

import InkeepCustom from "components/docs/navigation/inkeep/InkeepCustom";
const Container = styled.div`
  && {
    button {
      border-radius: 0.5rem !important;
    }
  }
`;
type InkeepModalProps = {
  isInkeepModalOpen: boolean;
  setIsInkeepModalOpen: Dispatch<SetStateAction<boolean>>;
};

const InkeepModal = ({ isInkeepModalOpen, setIsInkeepModalOpen }: InkeepModalProps) => {
  const modalRef = useRef<ModalRef>(null);
  return (
    <>
      <Modal
        ref={modalRef}
        isVisible={isInkeepModalOpen}
        showHeader={false}
        centered={false}
        noPadding
        setIsVisible={setIsInkeepModalOpen}
      >
        <Container>
          <InkeepCustom
            setIsInkeepOpen={setIsInkeepModalOpen}
            isInkeepOpen={isInkeepModalOpen}
          ></InkeepCustom>
        </Container>
      </Modal>
    </>
  );
};

export default InkeepModal;
