import { CenteredStack } from "components/generic/Stacks";
import React from "react";
import styled from "styled-components";
import { palette } from "styles/theme";
import { IconContainer } from "templates/docs/integrations/IntegrationsOverviewPage";
import { StaticImage } from "gatsby-plugin-image";
interface Props {
  /**
   * The text - always shown
   */
  text: string;

  /**
   * If necessary, provides an icon image left of the text
   */
  imageSrc?: string;
}

// Size of the icon in pixels
const DIMENSION = 24;

/**
 * By default, the contents don't wrap, but we do want them to
 * here for long API names like "Greenhouse - Job Boards API".
 */
const Stack = styled(CenteredStack)`
  white-space: normal;
  line-height: 1.25;
`;
export const StyledIconContainer = styled(IconContainer)`
  width: 24px;
`;
/**
 * Rounded, 24px icon
 */
const Icon = styled.img`
  background: ${palette.white};
  border: 1px solid ${palette.border};
  border-radius: ${DIMENSION}px;
  width: ${DIMENSION}px;
  height: ${DIMENSION}px;
`;

/**
 * Creates the optional icon that goes to the left of the tertiary
 * link in the docs sidebar if needed.
 */
const TertiaryLinkEntryContent = ({ text, imageSrc }: Props) =>
  imageSrc ? (
    <Stack $gap={12}>
      {text == "Gusto" ? (
        <StyledIconContainer>
          <StaticImage src="../../../assets/images/gusto_square_image.png" alt="" />
        </StyledIconContainer>
      ) : (
        <Icon src={imageSrc} />
      )}
      {text}
    </Stack>
  ) : (
    <>{text}</>
  );

export default TertiaryLinkEntryContent;
