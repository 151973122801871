import React, { forwardRef, useEffect, useImperativeHandle } from "react";
import ReactDOM from "react-dom";
import styled from "styled-components";
import { palette } from "../../styles/theme";
import { useModal } from "../../hooks/useModal";
import useEscPressed from "components/docs/hooks/useEscPressed";

type Props = React.ComponentPropsWithoutRef<"div"> & {
  /**
   * A wrapper value so a parent can change visibility of this modal
   */
  isVisible: boolean;

  /**
   * State change dispatch passed from a parent to change visibility from above.
   */
  setIsVisible: React.Dispatch<React.SetStateAction<boolean>>;

  /**
   * The modal content itself - will sit inside the ModalContainer.
   */
  children?: React.ReactNode;

  showHeader?: boolean;

  noPadding?: boolean;

  centered?: boolean;
};

/**
 * Allows using these modal attributes to type the ref that refers to this Modal
 */
export type ModalRef = {
  /**
   * Anyone with a ref to this element should be able to close this modal
   */
  closeModal: () => void;
};

// Makes sure this modal is above EVERYTHING else
const baseZLevel = 100000033;

// The class that gets added to mark the modal as in view (animates in)
const inViewClass = "in-view";

// How long it takes to animate the modal in/out
const animationDurationInMs = 200;

// Non-visually increases the size of the close button to make more clickable
const closeButtonPadding = "1rem";

/**
 * Wrapper for the aria labels and such
 */
const AriaWrapper = styled.div`
  && {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    z-index: ${baseZLevel + 10};
    width: 100%;
    height: 100%;
    overflow-x: hidden;
    overflow-y: hidden;
    outline: 0;
    pointer-events: none;
    transition:
      opacity ${animationDurationInMs}ms ease-out,
      transform ${animationDurationInMs}ms ease-out;
    transform: translateY(8px) scale(0.98);
    opacity: 0;

    &.${inViewClass} {
      transform: translateY(0);
      opacity: 1;
    }
  }
`;

/**
 * Just flows it so we can balance the container vertically
 */
const FlowContainer = styled.div<{ $centered: boolean }>`
  display: flex;
  justify-content: center;
  align-items: ${({ $centered }) => ($centered ? "center" : "start")};
  margin-top: ${({ $centered }) => ($centered ? 0 : "100px")};
  position: relative;
  width: 100%;
  height: 100%;
  pointer-events: none;
`;

/**
 * The modal itself, containing all content passed in. Rounded
 * corners, dark background, offset by 25% vertically by
 * default. Captures pointer events so clicks don't dismiss the
 * modal.
 * Also adjusts modal height with margin-bottom
 */

type ContainerProps = {
  noPadding: boolean;
};
const Container = styled.div<ContainerProps>`
  background: linear-gradient(180deg, ${palette.darkIndigo} 0%, ${palette.black} 100%),
    ${palette.darkIndigo};
  z-index: ${baseZLevel - 100};
  border-radius: 1rem;

  width: 100%;
  margin-bottom: 5vh;
  padding: ${(props) => (props.noPadding ? "" : "1rem")};
  color: ${palette.white};
  pointer-events: all;
`;

const Header = styled.div`
  display: flex;
  justify-content: flex-end;
  margin: -${closeButtonPadding};
`;

const CloseButton = styled.button`
  && {
    font-size: 1.25rem;
    padding: ${closeButtonPadding};
    cursor: pointer;
    border: none;
    background: none;
    color: ${palette.white};

    &:active {
      color: ${palette.gray};
    }
  }
`;

const ModalContainer = styled.div`
  @media (max-width: 576px) {
    margin: 0.5rem;
  }
  margin: 1.75rem auto;
  max-width: 600px;
  width: 100%;
`;
/**
 * @returns A generic modal component to use anywhere via Portals.
 * Add content to the modal with children, and this component
 * iself can be styled with styled-component (it gets passed
 * to the Container itself).
 */
const Modal = forwardRef<ModalRef, Props>(
  (
    {
      isVisible,
      setIsVisible,
      className,
      children,
      showHeader = true,
      noPadding = false,
      centered = true,
    },
    ref,
  ) => {
    const { isActuallyVisible, setVisibleWithAnimation, contentRef, backgroundRef } = useModal({
      visibleClass: inViewClass,
      animationDurationInMs,
    });
    const escButtonPressed = useEscPressed();

    const closeModal = () => setVisibleWithAnimation(false);

    // The parent's setting of isVisible should forward onto the hook
    // where it animates the visibility variable properly.
    useEffect(() => {
      setVisibleWithAnimation(isVisible);
    }, [isVisible]);

    useEffect(() => {
      if (escButtonPressed && isVisible) {
        closeModal();
      }
    }, [escButtonPressed]);

    // The hook's actually visible property is set when the animation ends
    // so when it changes, we update the parent's state variable to reflect it
    useEffect(() => {
      if (!isActuallyVisible) {
        setIsVisible(false);
      }
    }, [isActuallyVisible]);

    // This exposes closeModal (with an animation) to the parent if they set a
    // ref on the Modal itself - which is why this component has forwardRef around it
    useImperativeHandle(ref, () => ({
      closeModal,
    }));

    const modal = (
      <>
        <AriaWrapper
          ref={contentRef}
          className="merge-docs"
          aria-modal
          aria-hidden
          tabIndex={-1}
          role="dialog"
        >
          <FlowContainer $centered={centered}>
            <ModalContainer>
              <Container className={className} noPadding={noPadding}>
                {showHeader && (
                  <Header>
                    <CloseButton
                      type="button"
                      className="modal-close-button"
                      data-dismiss="modal"
                      aria-label="Close"
                      onClick={closeModal}
                    >
                      <span aria-hidden="true">&times;</span>
                    </CloseButton>
                  </Header>
                )}
                {children}
              </Container>
            </ModalContainer>
          </FlowContainer>
        </AriaWrapper>
      </>
    );

    // If there's no modal visible (when animations have finished), uses null to remove it from DOM
    return isVisible ? ReactDOM.createPortal(modal, document.body) : null;
  },
);

export default Modal;
