import type {
  InkeepAIChatSettings,
  InkeepSearchSettings,
  InkeepBaseSettings,
  InkeepModalSettings,
  AIChatDisclaimerSettings,
} from "@inkeep/uikit";

type InkeepSharedSettings = {
  baseSettings: InkeepBaseSettings;
  aiChatSettings: InkeepAIChatSettings;
  searchSettings: InkeepSearchSettings;
  modalSettings: InkeepModalSettings;
};

const INTRO_MESSAGE = `Hi! I'm an AI assistant, ask me anything about Merge.`;

const getInkeepSettings = (): InkeepSharedSettings => {
  const baseSettings: InkeepBaseSettings = {
    apiKey: process.env.GATSBY_INKEEP_API_KEY!,
    integrationId: process.env.GATSBY_INKEEP_INTEGRATION_ID!,
    organizationId: process.env.GATSBY_INKEEP_ORGANIZATION_ID!,
    primaryBrandColor: "#FFFFFF",
    organizationDisplayName: "Merge",
    customIcons: {
      switchToChat: { custom: "/inkeep/askAi-icon-black.svg" },
      chatSubmit: { custom: "/inkeep/send-chat-icon.svg" },
    },
    theme: {
      tokens: {
        zIndex: {
          // needed otherwise part of the search bar gets hidden under the sub navigation bar
          hide: -1,
          auto: "auto",
          base: 0,
          docked: 10,
          dropdown: 1000,
          sticky: 1100,
          banner: 1200,
          overlay: 1300,
          modal: 10400,
          popover: 10500,
          skipLink: 10600,
          toast: 10700,
          tooltip: 18000,
        },
      },
      stylesheetUrls: ["/inkeep/inkeep.css"],
    },
  };

  const modalSettings: InkeepModalSettings = {
    isModeSwitchingEnabled: true,
    defaultView: "SEARCH",
    forceInitialDefaultView: true,
  };

  const searchSettings: InkeepSearchSettings = {
    tabSettings: {
      disabledDefaultTabs: ["GitHub"],
    },
    placeholder: "Search docs...",
  };

  const aiChatDisclaimerSettings: AIChatDisclaimerSettings = {
    isDisclaimerEnabled: true,
    disclaimerLabel: "Disclaimer",
    disclaimerTooltip: `Information provided by this AI assistant is not guaranteed to be accurate or comprehensive. Please <a href="mailto: support@merge.dev">contact Merge</a> for any additional questions.`,
  };

  const aiChatSettings: InkeepAIChatSettings = {
    botAvatarSrcUrl: "/inkeep/askAi-icon-blue.svg",
    userAvatarSrcUrl: "/inkeep/user-icon-ai-chat.svg",
    introMessage: INTRO_MESSAGE,
    disclaimerSettings: aiChatDisclaimerSettings,
  };

  return { baseSettings, aiChatSettings, searchSettings, modalSettings };
};

export default getInkeepSettings;
